<script>
    import appConfig from "./../../../app.config.json";
    import Footer from "../../components/landing/Footer";
    import i18n from "../../i18n";
    import { authComputed } from "@/state/helpers";
    import NavBar from "@/components/landing/NavBar";

    export default {
        page: {
            title: "Starter",
            meta: [{
                name: "description",
                content: appConfig.description
            }],
        },
        data() {
            return {
                title: "Damask Trading",
                lan: i18n.locale,
                items: [{
                        text: "Pages",
                        href: "/",
                    },
                    {
                        text: "Damask",
                        active: true,
                    },
                ],
            };
        },
        components: {
          NavBar,
          Footer,
        },
        methods: {
        },
        computed: {
          ...authComputed,
        },
        mounted() {
            document.querySelector('.currentyear').innerHTML = new Date().getFullYear() + " © Damask";
        },
    };
</script>
<style>
ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}
</style>
<template>
    <div class="layout-wrapper landing">
      <NavBar></NavBar>


      <section class="section pb-0 hero-section bg-white" id="doc-privacy-policy">
        <div class="bg-white bg-overlay-pattern"></div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-sm-10">
              <div class="text-left mt-lg-5 pt-5">
                <h1 class="display-6 fw-semibold mb-3 lh-base">RETURN POLICY</h1>
                <h5>Last updated March 01, 2023</h5>
                <br><br>
                <p>
                  Thank you for your purchase. We hope you are happy with our service. However, if you are not completely satisfied with your purchase for any reason, you may return it to us for a full refund only. Please see below for more information on our return policy.
                </p>
                <br>
                <h4>RETURNS</h4>
                <p>
                  All returns must be submitted within thirty (30) days of the purchase date.
                </p>
                <h4>RETURN PROCESS</h4>
                <p>
                  To start return process, please email customer service at <a href="mail:info@damask-trading.com">info@damask-trading.com</a>.
                  Use the same email address that is registered in our system (Damask Trading). Will be nice if you can also provide payment details.
                </p>
                <h4>REFUNDS</h4>
                <p>
                  Please allow at least seven (7) days from the receipt of your email to process your return request.
                  It all depends on the payment method you used.
                  Please note, that refunds may take 1-2 billing cycles to appear on your credit card statement, depending on your credit card company.
                  We will notify you by email when your return has been processed.
                </p>
                <h4>EXCEPTIONS</h4>
                <ol>
                  <li>You can request to refund only for your first purchase.</li>
                </ol>
                <h4>QUESTIONS</h4>
                <p>
                  If you have any questions concerning our return policy, please contact us at: <a href="mail:info@damask-trading.com" style="white-space: nowrap;">info@damask-trading.com</a>
                </p>
              </div>
            </div>
          </div><!-- end row -->
        </div>
      </section>
      <Footer></Footer>
    </div>
</template>
